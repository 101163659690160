import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function brandsInfo({id}) {
  return omniFetch({
    url: config.v1Path + config.brandsInfoPath + "/" + id,
    method: "post",
    params: {}
  })
}

export function brandsList({favoriteBrands, pageIndex, pageSize}) {
  return omniFetch({
    url: config.v1Path + config.brandsListPath,
    method: "get",
    params: {favoriteBrands, pageIndex, pageSize}
  })
}

export function brandsGetBrandNameList({keywords}) {
  return omniFetch({
    url: config.v1Path + config.brandsGetBrandNameListPath + `${keywords ? "/" + keywords : ""}`,
    method: "get",
    params: {}
  })
}
