<script setup>
import {useBaseStore} from "~/stores/base";
import {PATH_RESUBSCRIBE} from "~/utils/constants";

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
</script>

<template>
  <div class="module-cancelled-mobile" v-if="isMobile">
    <div class="content">
      <div class="top">
        <div class="title">
          <img src="@/assets/img/icon-flash-account.svg" alt="flash">
          welcome back!
        </div>
        <ul>
          <li>
            Revitalize your style journey by re-subscribing now.
          </li>
          <li>
            Your wishlist is saved.
          </li>
          <li>
            We can't wait to welcome you back!
          </li>
        </ul>
      </div>
      <div class="bottom">
        <nuxt-link :to="PATH_RESUBSCRIBE" class="btn-primary">
          resubscribe
        </nuxt-link>
      </div>
    </div>
  </div>
  <div class="module-cancelled-desktop" v-else>
    <div class="content">
      <div class="top">
        <div class="title">
          <img src="@/assets/img/icon-flash-account.svg" alt="flash">
          welcome back!
        </div>
        <ul>
          <li>
            Revitalize your style journey by re-subscribing now.
          </li>
          <li>
            Your wishlist is saved.
          </li>
          <li>
            We can't wait to welcome you back!
          </li>
        </ul>
      </div>
      <div class="bottom">
        <nuxt-link :to="PATH_RESUBSCRIBE" class="btn-primary">
          resubscribe
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-cancelled-mobile {
  padding: 16px;

  .content {
    height: calc(100vh - 135px - 16px);
    border: 0.5px solid $color-gray-300;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .top {
      padding: 24px;

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;

        /* Eyebrow/small */
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 650;
        line-height: 12px; /* 120% */
        text-transform: uppercase;

        img {
          margin-bottom: 12px;
        }
      }

      ul {
        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 150% */
        margin-top: 12px;
      }
    }

    .bottom {
      padding: 12px;
    }
  }
}

.module-cancelled-desktop {
  padding: 16px;

  .content {
    height: calc(100vh - 150px - 16px);
    border: 0.5px solid $color-gray-300;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .top {
      padding: 24px;

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;

        /* Eyebrow/small */
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 650;
        line-height: 12px; /* 120% */
        text-transform: uppercase;

        img {
          margin-bottom: 12px;
        }
      }

      ul {
        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 150% */
        margin-top: 12px;
      }
    }

    .bottom {
      padding: 12px;
    }
  }
}
</style>
