<script setup>
import {useBaseStore} from "~/stores/base";
import {PATH_SUBSCRIBE} from "~/utils/constants";

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)
</script>

<template>
  <div class="module-not-subscribed-mobile" v-if="isMobile">
    <div class="title">
      Ready for BNTO!
    </div>
    <div class="text">
      Subscribe now to earn <span class="bold">${{ userInfo.subscribeEarnBonus }} bonus credits</span> that can be used to purchase
      rented items.
      <br>
      <br>
      Pause and cancel at any time!
    </div>
    <nuxt-link :to="PATH_SUBSCRIBE" class="btn-primary">
      subscribe
    </nuxt-link>
  </div>
  <div class="module-not-subscribed-desktop" v-else>
    <div class="title">
      Ready for BNTO!
    </div>
    <div class="text">
      Subscribe now to earn <span class="bold">${{ userInfo.subscribeEarnBonus }} bonus credits</span> that can be used to purchase
      rented items.
      <br>
      <br>
      Pause and cancel at any time!
    </div>
    <nuxt-link :to="PATH_SUBSCRIBE" class="btn-primary">
      subscribe
    </nuxt-link>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-not-subscribed-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 325px;
  padding: 28px 16px 16px 16px;
  gap: 28px;
  flex-shrink: 0;
  align-self: stretch;


  .title {
    /* Headline/small */
    font-family: "Alternate Gothic No3 D";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: uppercase;

    margin-top: 5px;
  }

  .text {
    text-align: center;
    /* Text xs/Regular */
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    .bold {
      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 650;
      line-height: 18px;
    }
  }

  .btn-primary {
    width: 100%;
  }
}

.module-not-subscribed-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 325px;
  padding: 28px 16px 16px 16px;
  gap: 28px;
  flex-shrink: 0;
  align-self: stretch;


  .title {
    /* Headline/small */
    font-family: "Alternate Gothic No3 D";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: uppercase;

    margin-top: 5px;
  }

  .text {
    text-align: center;
    /* Text xs/Regular */
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    .bold {
      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 650;
      line-height: 18px;
    }
  }

  .btn-primary {
    width: 100%;
  }
}
</style>
