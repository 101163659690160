<script setup>
import {useBaseStore} from "~/stores/base";
import {Navigation} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/vue";
import { v4 as uuidv4 } from 'uuid';

/** emits **/
const emits = defineEmits([])

/** props **/
const props = defineProps({
  productList: Array
})

/** data **/
const currentIndex = ref(0)
const id = ref(uuidv4())

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)

/** watch **/

/** methods **/
const onSwiper = (swiper) => {
};
const onSlideChange = (e) => {
  currentIndex.value = e.activeIndex
};

/** lifecycle **/
</script>

<template>
  <div class="module-bnto-box-mobile" v-if="isMobile">
    <nuxt-link :to="item.actionUrl" v-for="(item, index) in productList">
      <div :style="{backgroundImage: `url(${item.imgUrl})`}" class="img"/>
    </nuxt-link>
  </div>
  <div class="module-bnto-box-desktop" v-else>
    <div class="navigation" :class="`navigation-left-${id}`" v-if="productList.length > 4">
      <img src="@/assets/img/icon-left-black.svg" alt="left" :style="{opacity: currentIndex === 0 ? '0.2' : 1}">
    </div>
    <swiper
      class="swiper"
      grab-cursor
      :autoplay="false"
      :navigation="{
          prevEl: `.navigation-left-${id}`,
          nextEl: `.navigation-right-${id}`
        }"
      :modules="[Navigation]"
      :slides-per-view="4"
      :slides-per-group="2"
      :space-between="10"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-for="(item, index) in productList">
        <nuxt-link :to="item.actionUrl">
          <div :style="{backgroundImage: `url(${item.imgUrl})`}" class="img"/>
        </nuxt-link>
      </swiper-slide>
    </swiper>
    <div class="navigation" :class="`navigation-right-${id}`" v-if="productList.length > 4">
      <img src="@/assets/img/icon-right-black.svg" alt="right" :style="{opacity: currentIndex !== 0 ? '0.2' : 1}">
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-bnto-box-mobile {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .img {
    width: calc((100vw - 16px * 2 - 26px * 2 - 10px * 2 - 20px) / 3);
    height: calc((100vw - 16px * 2 - 26px * 2 - 10px * 2 - 20px) / 3 * 1.4);

    border-radius: 16px;
    background-color: #FFF;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.40) inset;
  }
}

.module-bnto-box-desktop {
  display: flex;
  align-items: center;
  gap: 10px;

  .navigation {
    width: 20px;
    height: 134px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background: #F0F0F0;
    cursor: pointer;

    img {
      width: 20px;
    }
  }

  .swiper {
    .swiper-slide {
      width: 96px;
      height: 134px;

      .img {
        width: 96px;
        height: 134px;

        border-radius: 16px;
        background-color: #FFF;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.40) inset;
      }
    }
  }
}
</style>
