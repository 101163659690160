<script setup>
import {useBaseStore} from "~/stores/base";

const emits = defineEmits(["change"])

/** props **/
const props = defineProps({
  title: {
    default: ""
  },
  placeholder: {
    type: [String, Number],
    default: "",
  },
  defaultValue: {
    type: [String, Number],
    default: "",
    required: true
  },
  options: {
    default: [],
    required: true
  },
  top: {
    default: '',
  },
  right: {
    default: '',
  },
  bottom: {
    default: '',
  },
  left: {
    default: '',
  },
  tips: {
    default: ""
  },
  errorTips: {
    default: ""
  },
  error: {
    default: false
  },
  inputClass: {
    default: ""
  },
})
/** data **/
const isShowOptions = ref(false)
const selectedValue = ref("")

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)

/** methods **/
const handleClick = () => {
  isShowOptions.value = !isShowOptions.value
}
const handleSelect = (option) => {
  emits("change", option)
  selectedValue.value = option
}
</script>

<template>
  <div class="module-selector-mobile" v-if="isMobile">
    <div class="input-title" v-if="title">{{ title }}</div>
    <div
      class="selector-wrapper-container"
      tabindex="0"
      :class="`${inputClass} ${error ? 'input-error' : ''}`"
      @click="handleClick"
    >
      <div class="selector-wrapper">
        <div class="selected">
          {{ selectedValue || defaultValue }}
          <span class="placeholder" v-if="(selectedValue !== 0 && !selectedValue) && (defaultValue !== 0 && !defaultValue)">{{placeholder}}</span>
        </div>
        <!-- 叹号 -->
        <div class="error-alert" v-if="error">
          <i class="iconfont icon-exclamation-circle"></i>
        </div>
        <!-- arrow -->
        <div class="arrow" :class="{rotate: isShowOptions}">
          <img src="@/assets/img/icon-arrow-down.svg" alt="clear">
        </div>
      </div>

      <transition name="fade" mode="out-in">
        <div class="selector-options" v-if="isShowOptions"
             :style="{top: top ? top : '', left: left ? left : '', bottom: bottom ? bottom : '', right: right ? right : ''}">
          <div class="option" v-for="option in options" @click="handleSelect(option)">{{ option }}</div>
        </div>
      </transition>
    </div>
    <div class="input-tips" v-if="!error && tips">
      <img src="@/assets/img/icon-info.svg" alt="info">
      <span v-html="tips"></span>
    </div>
    <div class="input-error-tips" v-html="errorTips" v-if="error"/>
  </div>
  <div class="module-selector-desktop" v-else>
    <div class="input-title" v-if="title">{{ title }}</div>
    <div
      class="selector-wrapper-container"
      tabindex="0"
      :class="`${inputClass} ${error ? 'input-error' : ''}`"
      @click="handleClick"
    >
      <div class="selector-wrapper">
        <div class="selected">
          {{ selectedValue || defaultValue }}
          <span class="placeholder" v-if="(selectedValue !== 0 && !selectedValue) && (defaultValue !== 0 && !defaultValue)">{{placeholder}}</span>
        </div>
        <!-- 叹号 -->
        <div class="error-alert" v-if="error">
          <i class="iconfont icon-exclamation-circle"></i>
        </div>
        <!-- arrow -->
        <div class="arrow" :class="{rotate: isShowOptions}">
          <img src="@/assets/img/icon-arrow-down.svg" alt="clear">
        </div>
      </div>

      <transition name="fade" mode="out-in">
        <div class="selector-options" v-if="isShowOptions"
             :style="{top: top ? top : '', left: left ? left : '', bottom: bottom ? bottom : '', right: right ? right : ''}">
          <div class="option" v-for="option in options" @click="handleSelect(option)">{{ option }}</div>
        </div>
      </transition>
    </div>
    <div class="input-tips" v-if="!error && tips">
      <img src="@/assets/img/icon-info.svg" alt="info">
      <span v-html="tips"></span>
    </div>
    <div class="input-error-tips" v-html="errorTips" v-if="error"/>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-selector-mobile {
  width: 100%;

  .input-title {
    //styleName: Text sm/Bold;
    font-family: "TWK Lausanne";
    font-weight: 650;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 6px;
  }

  .input-tips {
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    color: #292929;
    margin-top: 6px;

    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }

  .input-error-tips {
    /* Text xs/Regular */
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    color: #8f3838;
    margin-top: 6px;
  }

  .selector-wrapper-container {
    width: 100%;
    display: flex;
    box-shadow: 0 0 0 0.5px $color-gray-300;
    transition: all .2s linear;
    position: relative;

    &:focus {
      box-shadow: 0 0 0 1.5px $color-BNTO-beige-dark;
    }

    .selector-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px 12px;

      .selected {
        color: $color-gray-800;

        width: 100%;
        height: 20px;
        font-family: TWK Lausanne;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
        background-color: transparent;

        // 单行
        word-break: break-all;
        word-wrap: anywhere;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;

        .placeholder {
          color: $color-gray-500
        }
      }

      .error-alert {
        color: $color-BNTO-red-alert;

        display: flex;
        align-items: center;
        margin-left: 8px;
      }

      .arrow {
        display: flex;
        transition: all .2s ease-in-out;
      }

      .rotate {
        transform: rotate(180deg);
      }
    }

    .selector-options {
      width: 100%;
      max-height: 214px;
      overflow-y: auto;
      position: absolute;
      z-index: 2;
      background: #FFF;
      /* Shadow/lg */
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);

      padding: 4px 0px 0px 4px;
      display: flex;
      flex-direction: column;


      .option {
        width: 100%;

        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 150% */

        cursor: pointer;
        padding: 12px 12px 12px 8px;
        border-bottom: 1px solid $color-gray-200;

        &:hover {
          background-color: $color-gray-100;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .input-error {
    box-shadow: 0 0 0 0.5px $color-BNTO-red-alert;
  }
}

.module-selector-desktop {
  width: 100%;

  .input-title {
    //styleName: Text sm/Bold;
    font-family: "TWK Lausanne";
    font-weight: 650;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 6px;
  }

  .input-tips {
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    color: #292929;
    margin-top: 6px;

    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }

  .input-error-tips {
    /* Text xs/Regular */
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    color: #8f3838;
    margin-top: 6px;
  }

  .selector-wrapper-container {
    width: 100%;
    display: flex;
    box-shadow: 0 0 0 0.5px $color-gray-300;
    transition: all .2s linear;
    position: relative;

    &:focus {
      box-shadow: 0 0 0 1.5px $color-BNTO-beige-dark;
    }

    .selector-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px 12px;

      .selected {
        color: $color-gray-800;

        width: 100%;
        height: 20px;
        font-family: TWK Lausanne;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
        background-color: transparent;

        // 单行
        word-break: break-all;
        word-wrap: anywhere;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;

        .placeholder {
          color: $color-gray-500
        }
      }

      .error-alert {
        color: $color-BNTO-red-alert;

        display: flex;
        align-items: center;
        margin-left: 8px;
      }

      .arrow {
        display: flex;
        transition: all .2s ease-in-out;
      }

      .rotate {
        transform: rotate(180deg);
      }
    }

    .selector-options {
      width: 100%;
      max-height: 214px;
      overflow-y: auto;
      position: absolute;
      z-index: 2;
      background: #FFF;
      /* Shadow/lg */
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);

      padding: 4px 0px 0px 4px;
      display: flex;
      flex-direction: column;


      .option {
        width: 100%;

        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 150% */

        cursor: pointer;
        padding: 12px 12px 12px 8px;
        border-bottom: 1px solid $color-gray-200;

        &:hover {
          background-color: $color-gray-100;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .input-error {
    box-shadow: 0 0 0 0.5px $color-BNTO-red-alert;
  }
}
</style>
