<script setup>
import {useBaseStore} from "~/stores/base";
import {CARD_TYPE, ROLE} from "~/utils/constants";
import ModuleBigCard from "~/components/DrawerAI/ModuleBigCard.vue";
import ModuleBntoBox from "~/components/DrawerAI/ModuleBntoBox.vue";

/** emits **/
const emits = defineEmits(["rendered", "retry"])

/** props **/
const props = defineProps({
  message: {
    required: true
  }
})

/** data **/
const displayedMessage = ref('');

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)

/** methods **/
// 逐单词显示消息函数
const displayMessage = (content) => {
  return new Promise((resolve) => {
    let words;
    let isWordMode = content.includes(' '); // 判断是否包含空格

    if (isWordMode) {
      words = content.split(' '); // 英文按空格拆分为单词数组
    } else {
      words = content.split(''); // 中文逐字拆分
    }

    let index = 0; // 当前索引
    displayedMessage.value = ''; // 清空已显示内容

    const interval = setInterval(() => {
      if (index < words.length) {
        if (isWordMode) {
          // 英文逐单词显示，每个单词后加空格
          displayedMessage.value += words[index] + ' ';
        } else {
          // 中文逐字显示
          displayedMessage.value += words[index];
        }
        index++;
      } else {
        clearInterval(interval); // 当显示完成后，清除定时器
        resolve(); // 结束 Promise，表示显示完成
      }
    }, isWordMode ? 50 : 25); // 单词模式50ms，逐字模式25ms
  });
};
const handleRetry = () => {
  emits("retry")
}

/** lifecycle **/
onMounted(async () => {
  if (props.message.noAnimate) {
    displayedMessage.value = props.message.content
  } else {
    switch (props.message.role) {
      case ROLE.ASSISTANT:
        switch (props.message.cardType) {
          case CARD_TYPE.TEXT:
            await displayMessage(props.message.content); // 逐词显示新消息
            break;
          case CARD_TYPE.WAITING:
            // do nothing
            break;
          case CARD_TYPE.SYSTEM:
            // do nothing
            break;
          case CARD_TYPE.BIG_CARD:
            // do nothing
            break;
          case CARD_TYPE.BNTO_BOX:
            // do nothing
            break;
          case CARD_TYPE.ARTICLE_CARD:
            // do nothing
            break;
          case CARD_TYPE.ERROR:
            // do nothing
            break;
        }
        break;
      case ROLE.USER:
        displayedMessage.value = props.message.content
        break;
    }
  }
  emits("rendered")
})

</script>

<template>
  <div class="message-bubble-mobile" v-if="isMobile">
    <!-- 助手侧 -->
    <!-- 系统卡片 不显示 -->
    <div class="assistant" v-if="message.role === ROLE.ASSISTANT && message.cardType !== CARD_TYPE.SYSTEM">
      <div class="avatar">
        <img v-if="message.cardType === CARD_TYPE.WAITING || message.cardType === CARD_TYPE.ERROR || message.isBegin"
             src="@/assets/img/avatar-assistant.png" alt="avatar">
      </div>
      <div class="right">
        <div class="nickname" v-if=" message.cardType === CARD_TYPE.ERROR || message.isBegin">bnto</div>
        <div class="message">
          <div class="text" v-if="message.cardType === CARD_TYPE.TEXT">
            {{ displayedMessage }}
          </div>
          <div class="waiting" v-if="message.cardType === CARD_TYPE.WAITING">
            <div class="animate-ai-loading"/>
          </div>
          <div class="big-card" v-if="message.cardType === CARD_TYPE.BIG_CARD">
            <module-big-card :product-list="message.productList"/>
          </div>
          <div class="bnto-box" v-if="message.cardType === CARD_TYPE.BNTO_BOX">
            <module-bnto-box :product-list="message.productList"/>
          </div>
          <div class="article-card" v-if="message.cardType === CARD_TYPE.ARTICLE_CARD">
            <!-- todo -->
            <!-- 目前没有 -->
          </div>
          <div class="error" v-if="message.cardType === CARD_TYPE.ERROR">
            Something went wrong, please try again.
            <img src="@/assets/img/icon-refresh.svg" alt="refresh" @click="handleRetry">
          </div>
        </div>
      </div>
    </div>
    <!-- 用户侧 -->
    <div class="user" v-if="message.role === ROLE.USER">
      <div class="left">
        <div class="message">
          {{ displayedMessage }}
        </div>
      </div>
      <div class="avatar">
        <img src="@/assets/img/avatar-user.png" alt="avatar">
      </div>
    </div>
  </div>
  <div class="message-bubble-desktop" v-else>
    <!-- 助手侧 -->
    <!-- 系统卡片 不显示 -->
    <div class="assistant" v-if="message.role === ROLE.ASSISTANT && message.cardType !== CARD_TYPE.SYSTEM">
      <div class="avatar">
        <img v-if="message.cardType === CARD_TYPE.WAITING || message.cardType === CARD_TYPE.ERROR || message.isBegin"
             src="@/assets/img/avatar-assistant.png" alt="avatar">
      </div>
      <div class="right">
        <div class="nickname" v-if=" message.cardType === CARD_TYPE.ERROR || message.isBegin">bnto</div>
        <div class="message">
          <div class="text" v-if="message.cardType === CARD_TYPE.TEXT">
            {{ displayedMessage }}
          </div>
          <div class="waiting" v-if="message.cardType === CARD_TYPE.WAITING">
            <div class="animate-ai-loading"/>
          </div>
          <div class="big-card" v-if="message.cardType === CARD_TYPE.BIG_CARD">
            <module-big-card :product-list="message.productList"/>
          </div>
          <div class="bnto-box" v-if="message.cardType === CARD_TYPE.BNTO_BOX">
            <module-bnto-box :product-list="message.productList"/>
          </div>
          <div class="article-card" v-if="message.cardType === CARD_TYPE.ARTICLE_CARD">
            <!-- todo -->
            <!-- 目前没有 -->
          </div>
          <div class="error" v-if="message.cardType === CARD_TYPE.ERROR">
            Something went wrong, please try again.
            <img src="@/assets/img/icon-refresh.svg" alt="refresh" @click="handleRetry">
          </div>
        </div>
      </div>
    </div>
    <!-- 用户侧 -->
    <div class="user" v-if="message.role === ROLE.USER">
      <div class="left">
        <div class="message">
          {{ displayedMessage }}
        </div>
      </div>
      <div class="avatar">
        <img src="@/assets/img/avatar-user.png" alt="avatar">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";
$desktop-message-bubble-max-width: 476px;

.message-bubble-mobile {
  width: 100%;
  padding: 0 16px;

  .assistant {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    .avatar {
      flex-shrink: 0;
      display: flex;
      width: 26px;
      height: 26px;

      img {
        width: 26px;
        height: 26px;
      }
    }

    .right {
      width: calc(100% - 26px - 10px - 10px - 26px);
      display: flex;
      flex-direction: column;
      gap: 2px;

      .nickname {
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 16px; /* 114.286% */
        text-transform: uppercase;
      }

      .message {
        width: fit-content;
        max-width: 100%;

        text-align: left;
        font-family: "TWK Lausanne";
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;

        .text {
        }

        .waiting {
          padding-top: 1px;

          .animate-ai-loading {
            width: 24px;
            height: 24px;

            background: url("@/assets/img/AI loading.png") no-repeat;
            background-position-x: 0;
            background-position-y: 0;
            background-size: cover;
            animation: moveBackground 1.76s steps(44) infinite;
          }

          @keyframes moveBackground {
            0% {
              background-position-y: 0;
            }
            100% {
              background-position-y: -1056px; /* 44 * 26px = 1056px */
            }
          }
        }

        .error {
          display: flex;
          align-items: center;
          gap: 5px;
          color: $color-BNTO-red-alert;

          img {
            width: 20px;
            height: 20px;
            padding: 2px;

            box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
            background: $color-BNTO-beige-light;

            cursor: pointer;
          }
        }
      }
    }
  }

  .user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    .left {
      width: calc(100% - 26px - 10px - 10px - 26px);
      display: flex;
      justify-content: flex-end;

      .message {
        width: fit-content;
        max-width: 100%;

        text-align: left;
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 16px; /* 114.286% */
      }
    }

    .avatar {
      flex-shrink: 0;
      display: flex;

      img {
        width: 26px;
        height: 26px;
      }
    }
  }
}

.message-bubble-desktop {
  width: 100%;
  padding: 0 16px;

  .assistant {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    .avatar {
      flex-shrink: 0;
      display: flex;
      width: 26px;
      height: 26px;

      img {
        width: 26px;
        height: 26px;
      }
    }

    .right {
      width: calc(100% - 26px - 10px - 10px - 26px);
      display: flex;
      flex-direction: column;
      gap: 2px;

      .nickname {
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 16px; /* 114.286% */
        text-transform: uppercase;
      }

      .message {
        width: fit-content;
        max-width: $desktop-message-bubble-max-width;

        text-align: left;
        font-family: "TWK Lausanne";
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;

        .text {
        }

        .waiting {
          padding-top: 1px;

          .animate-ai-loading {
            width: 24px;
            height: 24px;

            background: url("@/assets/img/AI loading.png") no-repeat;
            background-position-x: 0;
            background-position-y: 0;
            background-size: cover;
            animation: moveBackground 1.76s steps(44) infinite;
          }

          @keyframes moveBackground {
            0% {
              background-position-y: 0;
            }
            100% {
              background-position-y: -1056px; /* 44 * 26px = 1056px */
            }
          }
        }

        .error {
          display: flex;
          align-items: center;
          gap: 5px;
          color: $color-BNTO-red-alert;

          img {
            width: 20px;
            height: 20px;
            padding: 2px;

            box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
            background: $color-BNTO-beige-light;

            cursor: pointer;
          }
        }
      }
    }
  }

  .user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    .left {
      width: calc(100% - 26px - 10px - 10px - 26px);
      display: flex;
      justify-content: flex-end;

      .message {
        width: fit-content;
        max-width: $desktop-message-bubble-max-width;

        text-align: left;
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 16px; /* 114.286% */
      }
    }

    .avatar {
      flex-shrink: 0;
      display: flex;

      img {
        width: 26px;
        height: 26px;
      }
    }
  }
}
</style>
