<script setup>
import {useBaseStore} from "~/stores/base";
import Drawer from "~/components/Drawer.vue";
import {menuIndex} from "~/api/api.menu";
import ModuleBrands from "~/components/DrawerMenu/ModuleBrands.vue";
import ModuleCategories from "~/components/DrawerMenu/ModuleCategories.vue";
import ModuleViavia from "~/components/DrawerMenu/ModuleViavia.vue";

const imageList = ref([])
const menuList = ref([])
const isShowBrands = ref(false)
const isShowCategories = ref(false)
const isShowViavia = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isShowDrawerMenu = computed(() => useBaseStore().getterIsShowDrawerMenu)

/** methods **/
const handleFirstLevelClick = (item) => {
  switch (item.title) {
    case "Brands":
      isShowBrands.value = true;
      break;
    case "Categories":
      isShowCategories.value = true;
      break;
    case "VIAVIA":
      isShowViavia.value = true;
      break;
    default:
      break;
  }
}
const handleCloseMenu = () => {
  isShowBrands.value = false;
  isShowCategories.value = false;
  isShowViavia.value = false;
  useBaseStore().updateIsShowDrawerMenu(false)
}
const getData = async () => {
  try {
    const {data, pending, error, refresh} = await useAsyncData(() => menuIndex())
    console.log("menu", data.value)
    imageList.value = data.value.result.imageList
    menuList.value = data.value.result.menuList
  } catch (e) {
    console.error(e)
  }
}

getData()

</script>

<template>
  <div class="drawer-menu-mobile" v-if="isMobile">
    <Drawer :open="isShowDrawerMenu" :position="'left'" :z-index="3" @close="handleCloseMenu">
      <div class="menu">
        <!-- menu -->
        <div class="block-menu">
          <template v-for="(item) in menuList">
            <!-- 有二级 -->
            <div class="menu-cell" @click="handleFirstLevelClick(item)" v-if="item.next">
              <div class="cell-left">
                <nuxt-link v-if="item.link" :to="item.link" @click.stop>
                  {{ item.title }}
                </nuxt-link>
                <span v-else>
                  {{ item.title }}
                </span>
              </div>
              <div class="cell-right">
                <i class="iconfont icon-arrow-right"></i>
              </div>
            </div>
            <!-- 没二级 -->
            <nuxt-link class="menu-cell" :to="item.link" v-else>
              <div class="cell-left">
                {{ item.title }}
              </div>
            </nuxt-link>
          </template>
        </div>

        <!-- image -->
        <div class="block-image">
          <nuxt-link :to="item.link" v-for="item in imageList">
            <img :src="item.cover.url" alt="image">
          </nuxt-link>
        </div>
      </div>

      <!-- brands -->
      <module-brands v-if="isShowBrands" @close="() => {isShowBrands = false}"/>

      <!-- categories -->
      <module-categories v-if="isShowCategories" @close="() => {isShowCategories = false}"/>

      <!-- VIAVIA -->
      <module-viavia v-if="isShowViavia" @close="() => {isShowViavia = false}"/>
    </Drawer>
  </div>
  <div class="drawer-menu-desktop" v-else>
    <Drawer :open="isShowDrawerMenu" :position="'left'" :z-index="3" @close="handleCloseMenu">
      <div class="menu">
        <!-- menu -->
        <div class="block-menu">
          <template v-for="(item) in menuList">
            <!-- 有二级 -->
            <div class="menu-cell" @click="handleFirstLevelClick(item)" v-if="item.next">
              <div class="cell-left">
                <nuxt-link v-if="item.link" :to="item.link" @click.stop>
                  {{ item.title }}
                </nuxt-link>
                <span v-else>
                  {{ item.title }}
                </span>
              </div>
              <div class="cell-right">
                <i class="iconfont icon-arrow-right"></i>
              </div>
            </div>
            <!-- 没二级 -->
            <nuxt-link class="menu-cell" :to="item.link" v-else>
              <div class="cell-left">
                {{ item.title }}
              </div>
            </nuxt-link>
          </template>
        </div>

        <!-- image -->
        <div class="block-image">
          <nuxt-link :to="item.link" v-for="item in imageList">
            <img :src="item.cover.url" alt="image">
          </nuxt-link>
        </div>
      </div>

      <!-- brands -->
      <module-brands v-if="isShowBrands" @close="() => {isShowBrands = false}"/>

      <!-- categories -->
      <module-categories v-if="isShowCategories" @close="() => {isShowCategories = false}"/>

      <!-- VIAVIA -->
      <module-viavia v-if="isShowViavia" @close="() => {isShowViavia = false}"/>
    </Drawer>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.drawer-menu-mobile {
  .menu {
    width: 375px;
    height: calc(100vh - $mobile-header-height);
    overflow-y: auto;
    padding-bottom: $mobile-safari-address-bar-height;

    .block-menu {
      padding: 16px;

      .menu-cell {
        .cell-left {
          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .block-image {
      margin-top: 32px;
      background-color: $color-gray-100;
      padding: 16px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;

      a {
        display: flex;

        img {
          width: 166px;
          height: 207.5px;
          object-fit: contain;
          object-position: center center;
        }
      }
    }
  }
}

.drawer-menu-desktop {
  .menu {
    width: 375px;
    height: calc(100vh - $desktop-header-height);
    overflow-y: auto;

    .block-menu {
      padding: 16px;

      .menu-cell {
        .cell-left {
          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .block-image {
      margin-top: 32px;
      background-color: $color-gray-100;
      padding: 16px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;

      a {
        display: flex;

        img {
          width: 158px;
          height: 197.5px;
          object-fit: contain;
          object-position: center center;
        }
      }
    }
  }
}
</style>
