<script setup>
import {useBaseStore} from "~/stores/base";
import {brandsGetBrandNameList} from "~/api/api.brands";
import {PATH_BRAND} from "~/utils/constants";

const emits = defineEmits(["close"])

const brands = ref([])

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)

const getData = async (keywords) => {
  try {
    const {data, pending, error, refresh} = await useAsyncData(() => brandsGetBrandNameList({keywords}))
    console.log("brands", data.value)
    brands.value = data.value.result
  } catch (e) {
    console.error(e)
  }
}

getData("")
</script>

<template>
  <div class="module-brands-mobile" v-if="isMobile">
    <div class="menu-header">
      <div class="back" @click="emits('close')">
        <img src="@/assets/img/icon-arrow-left.svg" alt="back">
      </div>
      <div class="title">brands</div>
    </div>

    <div class="menu-body">
      <div class="block">
        <nuxt-link class="menu-cell" :to="`${PATH_BRAND}/${brand.brandId}`" v-for="brand in brands">
          <div class="cell-left">
            {{ brand.brandName }}
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
  <div class="module-brands-desktop" v-else>
    <div class="menu-header">
      <div class="back" @click="emits('close')">
        <img src="@/assets/img/icon-arrow-left.svg" alt="back">
      </div>
      <div class="title">brands</div>
    </div>

    <div class="menu-body">
      <div class="block">
        <nuxt-link class="menu-cell" :to="`${PATH_BRAND}/${brand.brandId}`" v-for="brand in brands">
          <div class="cell-left">
            {{ brand.brandName }}
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-brands-mobile {
  width: 375px;
  height: calc(100vh - $mobile-header-height);
  margin-top: $mobile-header-height;
  padding-bottom: $mobile-safari-address-bar-height;

  position: fixed;
  left: 0;
  top: 0;

  background-color: white;

  overflow-y: scroll;
}

.module-brands-desktop {
  width: 375px;
  height: calc(100vh - $desktop-header-height);
  margin-top: $desktop-header-height;

  position: fixed;
  left: 0;
  top: 0;

  background-color: white;

  overflow-y: scroll;
}
</style>
