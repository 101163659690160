<script setup>
import {useBaseStore} from "~/stores/base";
import Drawer from "~/components/Drawer.vue";

const route = useRoute()
const router = useRouter()

const faqs = ref([
  {
    question: `<p style="color: var(--BNTO-Dark-Grey, #292929); font-family: 'TWK Lausanne'; font-size: 13px; font-weight: 300; line-height: 16px;">
      1) What is BNTO AI?
    </p>`,
    answer: `<p style="color: var(--Gray-500, #737373); font-family: 'TWK Lausanne'; font-size: 13px; font-weight: 300; line-height: 16px;">
      BNTO AI, powered by ALMA.AI, is our proprietary innovative experience designed to assist you with all your fashion needs. Here are some ideas to get you started:<br/><br/>
      - Get help with styling tips: "What should I wear for a weekend getaway?"<br/>
      - Find out more about fashion trends: "What are the latest summer fashion trends?"<br/>
      - Get product info that matters to you: "What materials are used in this dress?"
    </p>`
  },
  {
    question: `<p style="color: var(--BNTO-Dark-Grey, #292929); font-family: 'TWK Lausanne'; font-size: 13px; font-weight: 300; line-height: 16px;">
      2) How does BNTO generate responses to my questions?
    </p>`,
    answer: `<p style="color: var(--Gray-500, #737373); font-family: 'TWK Lausanne'; font-size: 13px; font-weight: 300; line-height: 16px;">
      BNTO AI has been trained using our extensive product catalog, product images, community Q&As, insights from our in-house styling experts, and web-sourced information on trends and pop culture events to answer your shopping and styling-related questions. For detailed product information, please refer to the product's detail page.<br/><br/>
      This tool is designed for shopping assistance and should not be relied upon for professional, medical, legal, or financial advice. Please avoid sharing personal or sensitive information. Although BNTO AI is updated with current events, there may be occasional limitations in its knowledge.<br/><br/>
      We welcome your feedback as we strive to improve our technology. If you have any further questions or feedback, please reach out to support@bnto.rent.
      </p>`
  },
  {
    question: `<p style="color: var(--BNTO-Dark-Grey, #292929); font-family: 'TWK Lausanne'; font-size: 13px; font-weight: 300; line-height: 16px;">
      3) Can I access and clear my chat history?
    </p>`,
    answer: `<p style="color: var(--Gray-500, #737373); font-family: 'TWK Lausanne'; font-size: 13px; font-weight: 300; line-height: 16px;">
      Yes, tap the three-dot menu in the top right corner of BNTO AI and choose "Clear chat history." We use your conversations with BNTO to improve future responses for up to 30 days, allowing BNTO to continue conversations from an earlier chat. <br/><br/> If you clear your chat history, all previous questions and answers will be removed from your view and will not be used for future responses.
    </p>`
  },
  {
    question: `<p style="color: var(--BNTO-Dark-Grey, #292929); font-family: 'TWK Lausanne'; font-size: 13px; font-weight: 300; line-height: 16px;">
      4) How do I use the traditional search feature?
    </p>`,
    answer: `<p style="color: var(--Gray-500, #737373); font-family: 'TWK Lausanne'; font-size: 13px; font-weight: 300; line-height: 16px;">
      You can still search by typing a product name or keywords into the search bar, which is located at the top of the screen in our iOS app and in the menu section. The experience you are testing is an enhancement to the existing search functionality.
    </p>`
  },
  {
    question: `<p style="color: var(--BNTO-Dark-Grey, #292929); font-family: 'TWK Lausanne'; font-size: 13px; font-weight: 300; line-height: 16px;">
      5) What data is collected and how is it used?
    </p>`,
    answer: `<p style="color: var(--Gray-500, #737373); font-family: 'TWK Lausanne'; font-size: 13px; font-weight: 300; line-height: 16px;">
      We collect and use personal information in accordance with our <a href="https://www.bnto.rent/landing/privacy-policy" style="text-decoration: underline;">Privacy Notice</a>.
    </p>`
  }
])

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isShowDrawerAIFaq = computed(() => useBaseStore().getterIsShowDrawerAIFaq)

/** methods **/
const handleCloseAI = () => {
  handleCloseAIFaq()
  useBaseStore().updateIsShowDrawerAIMore(false)
  // 获取当前路径，但去掉 hash 部分
  router.replace({
    path: router.currentRoute.value.path,
    query: router.currentRoute.value.query, // 保留查询参数
    hash: '' // 移除 hash
  });
}
const handleCloseAIFaq = () => {
  useBaseStore().updateIsShowDrawerAIFaq(false)
}
</script>

<template>
  <div class="drawer-ai-faq-mobile" v-if="isMobile">
    <Drawer
      :open="isShowDrawerAIFaq"
      :position="'bottom'"
      :frosted-glass="false"
      :border-radius="'20px 20px 0px 0px'"
      :z-index="4"
      @close="handleCloseAI"
    >
      <div class="drawer">
        <div class="drawer-header">
          <img class="close" src="@/assets/img/icon-close-black.svg" alt="icon" @click="handleCloseAI">
          <div class="header-title">
            FAQS
          </div>
          <div class="more" @click="handleCloseAIFaq">Done</div>
        </div>

        <div class="drawer-content">
          <div class="subtitle">Frequently Asked Questions</div>
          <div class="list">
            <div class="cell" v-for="item in faqs">
              <div class="question" v-html="item.question"/>
              <div class="answer" v-html="item.answer"/>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
  <div class="drawer-ai-faq-desktop" v-else>
    <Drawer
      :open="isShowDrawerAIFaq"
      :position="'right'"
      :frosted-glass="false"
      :z-index="3"
      @close="handleCloseAI"
    >
      <div class="drawer">
        <div class="drawer-header">
          <img class="close" src="@/assets/img/icon-close-black.svg" alt="icon" @click="handleCloseAI">
          <div class="header-title">
            FAQS
          </div>
          <div class="more" @click="handleCloseAIFaq">Done</div>
        </div>

        <div class="drawer-content">
          <div class="subtitle">Frequently Asked Questions</div>
          <div class="list">
            <div class="cell" v-for="item in faqs">
              <div class="question" v-html="item.question"/>
              <div class="answer" v-html="item.answer"/>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.drawer-ai-faq-mobile {
  .drawer {
    width: 100%;
    height: calc(100vh - $mobile-header-height - $mobile-safari-address-bar-height - 18px);
    background: white top center no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;

    .drawer-header {
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close {
        width: 30px;
        height: 30px;
        padding: 5px;
        border: 1px solid $color-BNTO-beige-dark;
        cursor: pointer;
      }

      .header-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;
        position: relative;

        .beta {
          position: absolute;
          right: -21.87px;
          bottom: 15px;

          color: $color-BNTO-beige-dark;
          font-family: "TWK Lausanne";
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: 12px; /* 120% */
          text-transform: lowercase;
        }
      }

      .more {
        color: $color-BNTO-beige-dark;
        font-family: "TWK Lausanne";
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px; /* 123.077% */
        cursor: pointer;
        padding: 5px;
      }
    }

    .drawer-content {
      overflow-y: auto;
      padding: 20px 0 50px 0;

      .subtitle {
        padding: 0 16px;
        margin-bottom: 16px;
        font-family: "TWK Lausanne";
        font-size: 13px;
        font-style: normal;
        font-weight: 650;
        line-height: 16px; /* 123.077% */
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .cell {
          padding: 0 16px;
          border-bottom: 1px solid $color-gray-200;

          .answer {
            margin-top: 16px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

.drawer-ai-faq-desktop {
  .drawer {
    width: 580px;
    height: calc(100vh - $desktop-header-height);
    background: white top center no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;

    .drawer-header {
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close {
        width: 40px;
        height: 40px;
        padding: 10px;
        border: 1px solid $color-BNTO-beige-dark;
        cursor: pointer;
      }

      .header-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;
        position: relative;

        .beta {
          position: absolute;
          right: -21.87px;
          bottom: 15px;

          color: $color-BNTO-beige-dark;
          font-family: "TWK Lausanne";
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: 12px; /* 120% */
          text-transform: lowercase;
        }
      }

      .more {
        color: $color-BNTO-beige-dark;
        font-family: "TWK Lausanne";
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px; /* 123.077% */
        cursor: pointer;
        padding: 10px;
      }
    }

    .drawer-content {
      overflow-y: auto;
      padding: 20px 0 50px 0;

      .subtitle {
        padding: 0 16px;
        margin-bottom: 16px;
        font-family: "TWK Lausanne";
        font-size: 13px;
        font-style: normal;
        font-weight: 650;
        line-height: 16px; /* 123.077% */
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .cell {
          padding: 0 16px;
          border-bottom: 1px solid $color-gray-200;

          .answer {
            margin-top: 16px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>
