<script setup>
import ModuleInput from "~/components/ModuleInput.vue";
import {USMobileFormatter} from "~/utils/common";
import {STATES} from "~/utils/constants";
import ModuleSelector from "~/components/ModuleSelector.vue";

const emits = defineEmits(["update"])

/** props **/
const props = defineProps({
  shippingAddress: {
    default: {}
  }
})

/** data **/
// 名
const firstName = ref("")
const firstNameError = ref(false)
const FIRST_NAME_ERROR_TIPS_MAP = {
  REQUIRED: "First Name is required"
}
const firstNameErrorTips = ref("")
// 姓
const lastName = ref("")
const lastNameError = ref(false)
const LAST_NAME_ERROR_TIPS_MAP = {
  REQUIRED: "Last Name is required"
}
const lastNameErrorTips = ref("")
// 街道
const streetAddress = ref("")
const streetAddressError = ref(false)
const STREET_ADDRESS_ERROR_TIPS_MAP = {
  REQUIRED: "Street Address is required"
}
const streetAddressErrorTips = ref("")
// 住所
const details = ref("")
const detailsError = ref(false)
const DETAIL_ERROR_TIPS_MAP = {}
const detailsErrorTips = ref("")
// 城市
const city = ref("")
const cityError = ref(false)
const CITY_ERROR_TIPS_MAP = {
  REQUIRED: "City is required"
}
const cityErrorTips = ref("")
// 州
const state = ref("")
const stateError = ref(false)
const STATE_ERROR_TIPS_MAP = {
  REQUIRED: "State is required"
}
const stateErrorTips = ref("")
// 邮政编码
const zip = ref("")
const zipError = ref(false)
const ZIP_ERROR_TIPS_MAP = {
  REQUIRED: "Zip is required"
}
const zipErrorTips = ref("")
// 电话
const mobile = ref("")
const mobileError = ref(false)
const MOBILE_ERROR_TIPS_MAP = {
  REQUIRED: "Mobile Number is required"
}
const mobileErrorTips = ref("")

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const btnConfirmDisabled = computed(() => {
  const all_filled = !!firstName.value && !!lastName.value && !!streetAddress.value && !!city.value && !!state.value && !!zip.value && !!mobile.value
  const valid = !firstNameError.value && !lastNameError.value && !streetAddressError.value && !detailsError.value && !cityError.value && !stateError.value && !zipError.value && !mobileError.value
  return !all_filled || !valid
})

/** watch **/
// 输入
watch(() => props.shippingAddress, (newV) => {
  firstName.value = newV.firstName
  lastName.value = newV.lastName
  streetAddress.value = newV.streetAddress
  details.value = newV.details
  city.value = newV.city
  state.value = newV.state
  zip.value = newV.zip
  mobile.value = USMobileFormatter(newV.mobile)
}, {immediate: true})

// 输出
watchEffect(() => {
  emits("update", {
    shippingAddress: {
      firstName: firstName.value,
      lastName: lastName.value,
      streetAddress: streetAddress.value,
      details: details.value,
      city: city.value,
      state: state.value,
      zip: zip.value,
      mobile: mobile.value,
    },
    btnConfirmDisabled: btnConfirmDisabled.value
  })
})

/** methods **/
const handleFirstNameBlur = () => {
  // reset
  firstNameError.value = false
  firstNameErrorTips.value = ""
  // 必填校验
  if (!firstName.value) {
    firstNameError.value = true
    firstNameErrorTips.value = FIRST_NAME_ERROR_TIPS_MAP.REQUIRED
    return
  }
}
const handleLastNameBlur = () => {
  // reset
  lastNameError.value = false
  lastNameErrorTips.value = ""
  // 必填校验
  if (!lastName.value) {
    lastNameError.value = true
    lastNameErrorTips.value = LAST_NAME_ERROR_TIPS_MAP.REQUIRED
    return
  }
}
const handleStreetAddressBlur = () => {
  // reset
  streetAddressError.value = false
  streetAddressErrorTips.value = ""
  // 必填校验
  if (!streetAddress.value) {
    streetAddressError.value = true
    streetAddressErrorTips.value = STREET_ADDRESS_ERROR_TIPS_MAP.REQUIRED
    return
  }
}
const handleDetailsBlur = () => {
  // reset
  detailsError.value = false
  detailsErrorTips.value = ""
}
const handleCityBlur = () => {
  // reset
  cityError.value = false
  cityErrorTips.value = ""
  // 必填校验
  if (!city.value) {
    cityError.value = true
    cityErrorTips.value = CITY_ERROR_TIPS_MAP.REQUIRED
    return
  }
}
const handleStateSelect = (e) => {
  // reset
  stateError.value = false
  stateErrorTips.value = ""
  state.value = e
}
const handleZipBlur = () => {
  // reset
  zipError.value = false
  zipErrorTips.value = ""
  // 必填校验
  if (!zip.value) {
    zipError.value = true
    zipErrorTips.value = ZIP_ERROR_TIPS_MAP.REQUIRED
    return
  }
}
const handleMobileInput = () => {
  let input = mobile.value.replace(/\D/g, ''); // 移除所有非数字字符
  if (input.startsWith("1")) input = input.slice(1) // 第一位不可以是1
  if (input.length > 0) {
    input = '(' + input;
  }
  if (input.length > 4) {
    input = input.slice(0, 4) + ') ' + input.slice(4);
  }
  if (input.length > 9) {
    input = input.slice(0, 9) + '-' + input.slice(9, 13);
  }
  mobile.value = input.slice(0, 14); // 限制最大长度为14个字符
}
const handleMobileBlur = () => {
  // reset
  mobileError.value = false
  mobileErrorTips.value = ""
  // 必填校验
  if (!mobile.value) {
    mobileError.value = true
    mobileErrorTips.value = MOBILE_ERROR_TIPS_MAP.REQUIRED
    return
  }
}
</script>

<template>
  <div class="content-shipping-address-mobile" v-if="isMobile">
    <div class="line">
      <module-input
        type="text"
        v-model="firstName"
        title="First Name"
        name="firstName"
        :max-length="32"
        :error-tips="firstNameErrorTips"
        :error="firstNameError"
        @blur="handleFirstNameBlur"
      />
      <div class="gap"/>
      <module-input
        type="text"
        v-model="lastName"
        title="Last Name"
        name="lastName"
        :max-length="32"
        :error-tips="lastNameErrorTips"
        :error="lastNameError"
        @blur="handleLastNameBlur"
      />
    </div>
    <div class="line">
      <module-input
        type="text"
        v-model="streetAddress"
        title="Street Address"
        name="address_line1"
        :error-tips="streetAddressErrorTips"
        :error="streetAddressError"
        @blur="handleStreetAddressBlur"
      />
    </div>
    <div class="line">
      <module-input
        type="text"
        v-model="details"
        title="Building, Apt, # Suite"
        name="address_line2"
        :error-tips="detailsErrorTips"
        :error="detailsError"
        @blur="handleDetailsBlur"
      />
      <div class="gap"/>
      <module-input
        type="text"
        v-model="city"
        title="City"
        name="city"
        :error-tips="cityErrorTips"
        :error="cityError"
        @blur="handleCityBlur"
      />
    </div>
    <div class="line">
      <module-selector
        :options="STATES"
        :default-value="state"
        :top="'40px'"
        title="State"
        name="state"
        :error-tips="stateErrorTips"
        :error="stateError"
        @change="handleStateSelect"
      />
      <div class="gap"/>
      <module-input
        type="text"
        v-model="zip"
        title="Zip"
        name="zip"
        :error-tips="zipErrorTips"
        :error="zipError"
        @blur="handleZipBlur"
      />
    </div>
    <div class="text">
      We’re unable to ship to PO Boxes, APO/FPO addresses or international addresses outside of the United States.
    </div>
    <div class="line">
      <module-input
        type="tel"
        v-model="mobile"
        title="Mobile Number (U.S. Only)"
        name="phone_number"
        max-length="14"
        :error-tips="mobileErrorTips"
        :error="mobileError"
        @input="handleMobileInput"
        @blur="handleMobileBlur"
      />
    </div>
  </div>
  <div class="content-shipping-address-desktop" v-else>
    <div class="line">
      <module-input
        type="text"
        v-model="firstName"
        title="First Name"
        name="firstName"
        :max-length="32"
        :error-tips="firstNameErrorTips"
        :error="firstNameError"
        @blur="handleFirstNameBlur"
      />
      <div class="gap"/>
      <module-input
        type="text"
        v-model="lastName"
        title="Last Name"
        name="lastName"
        :max-length="32"
        :error-tips="lastNameErrorTips"
        :error="lastNameError"
        @blur="handleLastNameBlur"
      />
    </div>
    <div class="line">
      <module-input
        type="text"
        v-model="streetAddress"
        title="Street Address"
        name="address_line1"
        :error-tips="streetAddressErrorTips"
        :error="streetAddressError"
        @blur="handleStreetAddressBlur"
      />
    </div>
    <div class="line">
      <module-input
        type="text"
        v-model="details"
        title="Building, Apt, # Suite"
        name="address_line2"
        :error-tips="detailsErrorTips"
        :error="detailsError"
        @blur="handleDetailsBlur"
      />
      <div class="gap"/>
      <module-input
        type="text"
        v-model="city"
        title="City"
        name="city"
        :error-tips="cityErrorTips"
        :error="cityError"
        @blur="handleCityBlur"
      />
    </div>
    <div class="line">
      <module-selector
        :options="STATES"
        :default-value="state"
        :top="'40px'"
        title="State"
        name="state"
        :error-tips="stateErrorTips"
        :error="stateError"
        @change="handleStateSelect"
      />
      <div class="gap"/>
      <module-input
        type="text"
        v-model="zip"
        title="Zip"
        name="zip"
        :error-tips="zipErrorTips"
        :error="zipError"
        @blur="handleZipBlur"
      />
    </div>
    <div class="text">
      We’re unable to ship to PO Boxes, APO/FPO addresses or international addresses outside of the United States.
    </div>
    <div class="line">
      <module-input
        type="tel"
        v-model="mobile"
        title="Mobile Number (U.S. Only)"
        name="phone_number"
        max-length="14"
        :error-tips="mobileErrorTips"
        :error="mobileError"
        @input="handleMobileInput"
        @blur="handleMobileBlur"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.content-shipping-address-mobile {
  width: 315px;
  margin: 0 auto;

  .content-title {
    //styleName: Text sm/Bold;
    font-family: "TWK Lausanne";
    font-weight: 650;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 16px;
  }

  .line {
    width: 100%;
    display: flex;
    margin-top: 16px;
    height: fit-content;

    .gap {
      width: 8px;
      flex-shrink: 0;
    }

  }

  .text {
    //styleName: Text sm/Regular;
    font-family: TWK Lausanne;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    margin-top: 16px;
  }
}

.content-shipping-address-desktop {
  width: 100%;
  margin: 0 auto;

  .content-title {
    //styleName: Text sm/Bold;
    font-family: "TWK Lausanne";
    font-weight: 650;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 16px;
  }

  .line {
    width: 100%;
    display: flex;
    margin-top: 16px;
    height: fit-content;

    .gap {
      width: 8px;
      flex-shrink: 0;
    }

  }

  .text {
    //styleName: Text sm/Regular;
    font-family: TWK Lausanne;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    margin-top: 16px;
  }
}
</style>
