import {useBaseStore} from "~/stores/base";

class MyWebSocket {
  constructor() {
    const userInfo = computed(() => useBaseStore().getterUserInfo);
    const deviceId = computed(() => useBaseStore().getterDeviceId);
    const ws = new WebSocket(`${import.meta.env.VITE_ORIGIN_AI_CHAT}?T=${new Date().getTime()}&cid=${deviceId.value}&user_id=${userInfo.value.userId}`);
    this.connect = ({onopen, onmessage, onclose}) => {
      // 连接打开时触发
      ws.onopen = function (event) {
        console.log("WebSocket 连接已建立");
        onopen();
      };

      // 监听服务器发送的数据
      ws.onmessage = function (event) {
        console.log("接收到的消息:", event.data);
        if (event.data !== 'ping') onmessage(event.data);
      };

      // 连接关闭时触发
      ws.onclose = function (event) {
        console.log("WebSocket 连接已关闭");
        onclose();
      };

      // 连接发生错误时触发
      ws.onerror = function (error) {
        console.error("WebSocket 发生错误:", error);
      };
    };
    this.send = (text) => {
      console.log("消息已发送", text);
      ws.send(text);
    };
    this.disconnect = () => {
      ws.close();
    };
  }
}

export default MyWebSocket
