<template>
  <div
    class="scroll-wrapper"
    ref="scrollWrapper"
    @mouseenter="stopScroll"
    @mouseleave="startScroll"
    @touchstart="stopScrollMobile"
    @touchend="startScrollMobile"
  >
    <div class="scroll-content" :class="{ 'scrolling': isScrollable, 'paused': isPaused }">
      <!-- 原始内容 -->
      <div v-for="(tip, index) in tips" :key="index" class="tip-item" @click="emits('tip-select', tip.title)">
        {{ tip.title }}
      </div>
      <!-- 如果内容超过容器宽度，则渲染重复内容用于循环滚动 -->
      <div v-if="isScrollable" v-for="(tip, index) in tips" :key="`copy-${index}`" class="tip-item" @click="emits('tip-select', tip.title)">
        {{ tip.title }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import {useBaseStore} from "~/stores/base";

const emits = defineEmits(["tip-select"])

const props = defineProps({
  tips: Array,
});

const scrollWrapper = ref(null);
const isScrollable = ref(false);
const isPaused = ref(false);

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)

// 鼠标悬停时停止滚动
const stopScroll = () => {
  if(!isMobile.value) isPaused.value = true;
};

// 鼠标移出时恢复滚动
const startScroll = () => {
  if(!isMobile.value) isPaused.value = false;
};

// 触摸
const stopScrollMobile = () => {
  if(isMobile.value) isPaused.value = true;
};

// 移开手指
const startScrollMobile = () => {
  if(isMobile.value) isPaused.value = false;
};

onMounted(() => {
  const wrapperWidth = scrollWrapper.value.offsetWidth;
  const contentWidth = scrollWrapper.value.querySelector('.scroll-content').scrollWidth;

  // 如果内容宽度大于容器宽度，设置为可滚动
  if (contentWidth > wrapperWidth) {
    isScrollable.value = true;
  }
});
</script>

<style scoped lang="scss">
.scroll-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;

  .scroll-content {
    display: flex;
    width: max-content;
    gap: 5px;

    .tip-item {
      white-space: nowrap;
      display: inline-block;

      border-radius: 8px;
      border: 1px solid rgba(241, 232, 228, 0.50);
      background: linear-gradient(88deg, #F1E8E4 7.23%, #FFF 98.42%);

      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */

      padding: 4px 12px;

      cursor: pointer;
    }
  }

  /* 只有在内容超出时才滚动 */
  .scrolling {
    animation: scroll 30s linear infinite;
  }

  .paused {
    animation-play-state: paused;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
}
</style>
