<script setup>
import {useBaseStore} from "~/stores/base";

const emits = defineEmits(["close"])

/** props **/
const props = defineProps({
  title: {
    default: "",
    required: true
  },
  list: {
    default: [],
    required: true
  }
})

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
</script>

<template>
  <div class="module-category-children-mobile" v-if="isMobile">
    <div class="menu-header">
      <div class="back" @click="emits('close')">
        <img src="@/assets/img/icon-arrow-left.svg" alt="back">
      </div>
      <div class="title">{{ title }}</div>
    </div>

    <div class="menu-body">
      <div class="block">
        <nuxt-link :to="cell.link" class="menu-cell" v-for="cell in list">
          <div class="cell-left">
            {{ cell.name }}
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
  <div class="module-category-children-desktop" v-else>
    <div class="menu-header">
      <div class="back" @click="emits('close')">
        <img src="@/assets/img/icon-arrow-left.svg" alt="back">
      </div>
      <div class="title">{{ title }}</div>
    </div>

    <div class="menu-body">
      <div class="block">
        <nuxt-link :to="cell.link" class="menu-cell" v-for="cell in list">
          <div class="cell-left">
            {{ cell.name }}
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-category-children-mobile {
  width: 375px;
  height: calc(100vh - $mobile-header-height);
  margin-top: $mobile-header-height;
  padding-bottom: $mobile-safari-address-bar-height;
  overflow-y: auto;

  position: fixed;
  left: 0;
  top: 0;

  background-color: white;
}

.module-category-children-desktop {
  width: 375px;
  height: calc(100vh - $desktop-header-height);
  margin-top: $desktop-header-height;
  overflow-y: scroll;

  position: fixed;
  left: 0;
  top: 0;

  background-color: white;
}
</style>
