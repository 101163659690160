<script setup>
import {useBaseStore} from "~/stores/base";

const emits = defineEmits(["close"])

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
</script>

<template>
  <div class="module-viavia-mobile" v-if="isMobile">
    <div class="menu-header">
      <div class="back" @click="emits('close')">
        <img src="@/assets/img/icon-arrow-left.svg" alt="back">
      </div>
      <div class="title">viavia</div>
    </div>
  </div>
  <div class="module-viavia-desktop" v-else>
    <div class="menu-header">
      <div class="back" @click="emits('close')">
        <img src="@/assets/img/icon-arrow-left.svg" alt="back">
      </div>
      <div class="title">viavia</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-viavia-mobile {
  width: 375px;
  height: calc(100vh - $mobile-header-height);
  margin-top: $mobile-header-height;
  padding-bottom: $mobile-safari-address-bar-height;
  overflow-y: auto;

  position: fixed;
  left: 0;
  top: 0;

  background-color: white;
}

.module-viavia-desktop {
  width: 375px;
  height: calc(100vh - $desktop-header-height);
  margin-top: $desktop-header-height;

  position: fixed;
  left: 0;
  top: 0;

  background-color: white;
}
</style>
