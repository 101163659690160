<script setup>
import Modal from "./Modal.vue"
import {useBaseStore} from "~/stores/base";
import {URL_APP_DOWNLOAD} from "~/utils/constants";

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isShowModalDownload = computed(() => useBaseStore().getterIsShowModalDownload)

/** methods **/
const handleClose = () => {
  useBaseStore().updateIsShowModalDownload(false)
}
</script>

<template>
  <div class="modal-download-mobile" v-if="isMobile">
    <Modal :open="isShowModalDownload" @close="handleClose" z-index="3">
      <nuxt-link :to="URL_APP_DOWNLOAD">
        <div class="modal-download">
          <img class="close" src="@/assets/img/icon-close-black.svg" alt="close" @click.prevent="handleClose">
        </div>
      </nuxt-link>
    </Modal>
  </div>
  <div class="modal-download-desktop" v-else>
    <Modal :open="isShowModalDownload" @close="handleClose" z-index="3">
      <nuxt-link :to="URL_APP_DOWNLOAD">
        <div class="modal-download">
          <img class="close" src="@/assets/img/icon-close-black.svg" alt="close" @click.prevent="handleClose">
        </div>
      </nuxt-link>
    </Modal>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.modal-download-mobile {
  .modal-download {
    width: 300px;
    height: 468px;
    background: url("@/assets/img/bg-download-mobile.png") center center no-repeat;
    background-size: cover;
    position: relative;
    cursor: pointer;

    .close {
      position: absolute;
      right: 11px;
      top: 11px;
      width: 30px;
      height: 30px;
      padding: 6px;
      cursor: pointer;
    }
  }
}

.modal-download-desktop {
  .modal-download {
    width: 900px;
    height: 674px;
    background: url("@/assets/img/bg-download-pc.png") center center no-repeat;
    background-size: cover;
    position: relative;
    cursor: pointer;

    .close {
      position: absolute;
      right: 11px;
      top: 11px;
      width: 30px;
      height: 30px;
      padding: 6px;
      cursor: pointer;
    }
  }
}
</style>
