<script setup>
import {useBaseStore} from "~/stores/base";

/** emits **/
const emits = defineEmits([])

/** props **/
const props = defineProps({
  product: Object
})

/** data **/

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)

/** watch **/

/** methods **/

/** lifecycle **/

</script>

<template>
  <div class="module-preview-item-mobile" v-if="isMobile">
    <div class="left">
      <img :src="product.productImages[0].url" alt="img">
      <div class="info">
        <span class="brand">{{ product.brand }}</span>
        <span class="name">{{ product.productName }}</span>
        <span class="price">Retail: ${{ product.originalPrice }}</span>
        <span class="size">Size: {{ product.size }}</span>
      </div>
    </div>
    <div class="right" v-if="product.premium">
      <img src="@/assets/img/icon-diamond.svg" alt="diamond">
      +${{ product.premiumFee }}
    </div>
  </div>
  <div class="module-preview-item-desktop" v-else>
    <div class="left">
      <img :src="product.productImages[0].url" alt="img">
      <div class="info">
        <span class="brand">{{ product.brand }}</span>
        <span class="name">{{ product.productName }}</span>
        <span class="price">Retail: ${{ product.originalPrice }}</span>
        <span class="size">Size: {{ product.size }}</span>
      </div>
    </div>
    <div class="right" v-if="product.premium">
      <img src="@/assets/img/icon-diamond.svg" alt="diamond">
      +${{ product.premiumFee }}
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-preview-item-mobile {
  display: flex;
  justify-content: space-between;
  align-self: stretch;

  // 0.5px border
  box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
  background: #FFF;

  .left {
    display: flex;
    gap: 12px;

    img {
      width: 58px;
      height: 82px;
      object-position: center center;
      object-fit: cover;
    }

    .info {
      /* Text xs/Regular */
      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */

      display: flex;
      flex-direction: column;

      padding: 2px 0 0 8px;

      .brand {
        text-overflow: ellipsis;
        /* Text xs/Bold */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 150% */

        margin-bottom: 2px;
      }

      .name {
        // 单行
        word-break: break-all;
        word-wrap: anywhere;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;
      }

      .price {
      }

      .size {
      }
    }
  }

  .right {
    padding: 2px 8px 0 0;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    /* Text xs/Regular */
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.module-preview-item-desktop {
  display: flex;
  justify-content: space-between;
  align-self: stretch;

  // 0.5px border
  box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
  background: #FFF;

  .left {
    display: flex;
    gap: 12px;

    img {
      width: 58px;
      height: 82px;
      object-position: center center;
      object-fit: cover;
    }

    .info {
      /* Text xs/Regular */
      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */

      display: flex;
      flex-direction: column;

      padding: 2px 0 0 8px;

      .brand {
        text-overflow: ellipsis;
        /* Text xs/Bold */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 150% */

        margin-bottom: 2px;
      }

      .name {
        // 单行
        word-break: break-all;
        word-wrap: anywhere;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;
      }

      .price {
      }

      .size {
      }
    }
  }

  .right {
    padding: 2px 8px 0 0;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    /* Text xs/Regular */
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
