<script setup>
import {useBaseStore} from "~/stores/base";
import {globalUpdateUserInfo, sleep} from "~/utils/common";
import {memberUnPause} from "~/api/api.member";
import {Loading} from "@element-plus/icons-vue";
import {PATH_RESUME_SUBSCRIPTION} from "~/utils/constants";

const BNTOMessage = useNuxtApp().$BNTOMessage
const isLoading = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)

const handleUnPause = async () => {
  if (isLoading.value) return
  try {
    isLoading.value = true
    await memberUnPause()
    await sleep(2000)
    await globalUpdateUserInfo()
    isLoading.value = false
    BNTOMessage.sparkle({
      title: "bnto unpaused",
      message: "You can now fill your BNTO by adding your favorite styles!",
    })
  } catch (e) {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="module-paused-mobile" v-if="isMobile">
    <div class="content">
      <div class="top">
        <div class="title">
          <img src="@/assets/img/icon-human.svg" alt="human">
          wake up and shine!
        </div>
        <ul>
          <li>
            You paused your subscription with all of your bonus credits and rental history saved.
          </li>
          <li v-if="userInfo.pausedExpired">
            If you want to rent something before your next billing date, you can resume your subscription now and your
            next billing date will start today.
          </li>
          <li v-else>
            If you didn’t mean to pause it, you can UNPAUSE. Your next billing cycle will remain the same as before you
            paused, i.e. <span class="bold">{{userInfo.nextBillingDate}}</span>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <!-- Expired -->
        <nuxt-link v-if="userInfo.pausedExpired" :to="PATH_RESUME_SUBSCRIPTION" class="btn-primary">
          resume Subscription
        </nuxt-link>
        <!-- Unexpired -->
        <div v-else class="btn-primary" :class="{'btn-disabled': isLoading}" @click="handleUnPause">
          <el-icon v-if="isLoading" class="is-loading" color="white" size="16" style="margin-right: 8px">
            <Loading/>
          </el-icon>
          unPaused
        </div>
      </div>
    </div>
  </div>
  <div class="module-paused-desktop" v-else>
    <div class="content">
      <div class="top">
        <div class="title">
          <img src="@/assets/img/icon-human.svg" alt="human">
          wake up and shine!
        </div>
        <ul>
          <li>
            You paused your subscription with all of your bonus credits and rental history saved.
          </li>
          <li v-if="userInfo.pausedExpired">
            If you want to rent something before your next billing date, you can resume your subscription now and your
            next billing date will start today.
          </li>
          <li v-else>
            If you didn’t mean to pause it, you can UNPAUSE. Your next billing cycle will remain the same as before you
            paused, i.e. <span class="bold">{{userInfo.nextBillingDate}}</span>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <!-- Expired -->
        <nuxt-link v-if="userInfo.pausedExpired" :to="PATH_RESUME_SUBSCRIPTION" class="btn-primary">
          resume Subscription
        </nuxt-link>
        <!-- Unexpired -->
        <div v-else class="btn-primary" :class="{'btn-disabled': isLoading}" @click="handleUnPause">
          <el-icon v-if="isLoading" class="is-loading" color="white" size="16" style="margin-right: 8px">
            <Loading/>
          </el-icon>
          unPaused
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-paused-mobile {
  padding: 16px;

  .content {
    height: calc(100vh - 175px - 16px);
    border: 0.5px solid $color-gray-300;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .top {
      padding: 24px;

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;

        /* Eyebrow/small */
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 650;
        line-height: 12px; /* 120% */
        text-transform: uppercase;

        img {
          margin-bottom: 12px;
        }
      }

      ul {
        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 150% */
        margin-top: 12px;

        .bold {
          font-weight: 650;
        }
      }
    }

    .bottom {
      padding: 12px;
    }
  }
}

.module-paused-desktop {
  padding: 16px;

  .content {
    height: calc(100vh - 190px - 16px);
    border: 0.5px solid $color-gray-300;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .top {
      padding: 24px;

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;

        /* Eyebrow/small */
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 650;
        line-height: 12px; /* 120% */
        text-transform: uppercase;

        img {
          margin-bottom: 12px;
        }
      }

      ul {
        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 150% */
        margin-top: 12px;

        .bold {
          font-weight: 650;
        }
      }
    }

    .bottom {
      padding: 12px;
    }
  }
}
</style>
