import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function aiHistoryList({pageIndex, pageSize}) {
  return omniFetch({
    url: config.v1Path + config.aiHistoryListPath,
    method: "get",
    params: {
      pageIndex,
      pageSize
    }
  })
}

export function aiHistoryClear() {
  return omniFetch({
    url: config.v1Path + config.aiHistoryClearPath,
    method: "post",
    params: {}
  })
}
