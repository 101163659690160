<script setup>
import {useBaseStore} from "~/stores/base";
import Drawer from "~/components/Drawer.vue";
import {Loading} from "@element-plus/icons-vue";
import {aiHistoryClear} from "~/api/api.ai";

const route = useRoute()
const router = useRouter()

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isShowDrawerAIMore = computed(() => useBaseStore().getterIsShowDrawerAIMore)
const clearStatus = computed(() => useBaseStore().getterDrawerAiClearStatus) // 0 未清理 1 清理中 2 已清理

watch(isShowDrawerAIMore, () => {
  useBaseStore().updateDrawerAiClearStatus(0)
})

/** methods **/
const handleCloseAI = () => {
  handleCloseAIMore()
  // 获取当前路径，但去掉 hash 部分
  router.replace({
    path: router.currentRoute.value.path,
    query: router.currentRoute.value.query, // 保留查询参数
    hash: '' // 移除 hash
  });
}
const handleCloseAIMore = () => {
  useBaseStore().updateIsShowDrawerAIMore(false)
}
const handleFAQ = () => {
  useBaseStore().updateIsShowDrawerAIFaq(true)
}
const handleClear = async () => {
  if (clearStatus.value === 2) return
  useBaseStore().updateDrawerAiClearStatus(1)
  await aiHistoryClear()
  useBaseStore().updateDrawerAiClearStatus(2)
}
</script>

<template>
  <div class="drawer-ai-more-mobile" v-if="isMobile">
    <Drawer
      :open="isShowDrawerAIMore"
      :position="'bottom'"
      :frosted-glass="false"
      :border-radius="'20px 20px 0px 0px'"
      :z-index="4"
      @close="handleCloseAI"
    >
      <div class="drawer">
        <div class="drawer-header">
          <img class="close" src="@/assets/img/icon-close-black.svg" alt="icon" @click="handleCloseAI">
          <div class="header-title">
            <img src="@/assets/img/icon-ai.svg" alt="icon">
            bnto ai
            <span class="beta">beta</span>
          </div>
          <div class="more" @click="handleCloseAIMore">Done</div>
        </div>

        <div class="drawer-content">
          <div class="cell" @click="handleFAQ">
            <div class="cell-left">
              <img src="@/assets/img/icon-question.svg" alt="icon">
              <span>BNTO FAQs</span>
            </div>
            <div class="cell-right">
              <img src="@/assets/img/icon-arrow-right.svg" alt="icon">
            </div>
          </div>
          <div class="cell" @click="handleClear">
            <div class="cell-left">
              <img src="@/assets/img/icon-trash.svg" alt="icon" v-if="clearStatus === 0">
              <el-icon class="is-loading" color="gray" size="24" v-if="clearStatus === 1">
                <Loading/>
              </el-icon>
              <img src="@/assets/img/icon-success.svg" alt="icon" v-if="clearStatus === 2">
              <span :style="{color: clearStatus === 1 ? 'gray' : ''}">Clear Chat History</span>
            </div>
            <div class="cell-right">
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
  <div class="drawer-ai-more-desktop" v-else>
    <Drawer
      :open="isShowDrawerAIMore"
      :position="'right'"
      :frosted-glass="false"
      :z-index="3"
      @close="handleCloseAI"
    >
      <div class="drawer">
        <div class="drawer-header">
          <img class="close" src="@/assets/img/icon-close-black.svg" alt="icon" @click="handleCloseAI">
          <div class="header-title">
            <img src="@/assets/img/icon-ai.svg" alt="icon">
            bnto ai
            <span class="beta">beta</span>
          </div>
          <div class="more" @click="handleCloseAIMore">Done</div>
        </div>

        <div class="drawer-content">
          <div class="cell" @click="handleFAQ">
            <div class="cell-left">
              <img src="@/assets/img/icon-question.svg" alt="icon">
              <span>BNTO FAQs</span>
            </div>
            <div class="cell-right">
              <img src="@/assets/img/icon-arrow-right.svg" alt="icon">
            </div>
          </div>
          <div class="cell" @click="handleClear">
            <div class="cell-left">
              <img src="@/assets/img/icon-trash.svg" alt="icon" v-if="clearStatus === 0">
              <el-icon class="is-loading" color="gray" size="24" v-if="clearStatus === 1">
                <Loading/>
              </el-icon>
              <img src="@/assets/img/icon-success.svg" alt="icon" v-if="clearStatus === 2">
              <span :style="{color: clearStatus === 1 ? 'gray' : ''}">Clear Chat History</span>
            </div>
            <div class="cell-right">
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.drawer-ai-more-mobile {
  .drawer {
    width: 100%;
    height: calc(100vh - $mobile-header-height - $mobile-safari-address-bar-height - 18px);
    background: white top center no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;

    .drawer-header {
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close {
        width: 30px;
        height: 30px;
        padding: 5px;
        border: 1px solid $color-BNTO-beige-dark;
        cursor: pointer;
      }

      .header-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;
        position: relative;

        .beta {
          position: absolute;
          right: -21.87px;
          bottom: 15px;

          color: $color-BNTO-beige-dark;
          font-family: "TWK Lausanne";
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: 12px; /* 120% */
          text-transform: lowercase;
        }
      }

      .more {
        color: $color-BNTO-beige-dark;
        font-family: "TWK Lausanne";
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px; /* 123.077% */
        cursor: pointer;
        padding: 5px;
      }
    }

    .drawer-content {
      margin-top: 20px;

      .cell {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 16px;
        border-bottom: 1px $color-gray-100 solid;

        font-family: "TWK Lausanne";
        font-size: 13px;
        font-style: normal;
        font-weight: 650;
        line-height: 16px; /* 123.077% */

        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        .cell-left {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 24px;
            height: 24px;
          }
        }

        .cell-right {
        }
      }
    }
  }
}

.drawer-ai-more-desktop {
  .drawer {
    width: 580px;
    height: calc(100vh - $desktop-header-height);
    background: white top center no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;

    .drawer-header {
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close {
        width: 40px;
        height: 40px;
        padding: 10px;
        border: 1px solid $color-BNTO-beige-dark;
        cursor: pointer;
      }

      .header-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;
        position: relative;

        .beta {
          position: absolute;
          right: -21.87px;
          bottom: 15px;

          color: $color-BNTO-beige-dark;
          font-family: "TWK Lausanne";
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: 12px; /* 120% */
          text-transform: lowercase;
        }
      }

      .more {
        color: $color-BNTO-beige-dark;
        font-family: "TWK Lausanne";
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px; /* 123.077% */
        cursor: pointer;
        padding: 10px;
      }
    }

    .drawer-content {
      margin-top: 20px;

      .cell {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 16px;
        border-bottom: 1px $color-gray-100 solid;

        font-family: "TWK Lausanne";
        font-size: 13px;
        font-style: normal;
        font-weight: 650;
        line-height: 16px; /* 123.077% */

        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        .cell-left {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 24px;
            height: 24px;
          }
        }

        .cell-right {
        }
      }
    }
  }
}
</style>
