<script setup>
import {useBaseStore} from "~/stores/base";
import ModuleLoadingProgress from "~/components/LoadingProgress/ModuleLoadingProgress.vue";

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isShowModalLoadingProgress = computed(() => useBaseStore().getterIsShowModalLoadingProgress)

/** watch **/
watch(isShowModalLoadingProgress, (open) => {
  if (open) {
    document.body.style.overflow = "hidden"
  } else {
    document.body.style.overflow = "auto"
  }
})
</script>

<template>
  <div class="modal-loading-progress-mobile" v-if="isMobile && isShowModalLoadingProgress">
    <module-loading-progress
      :start="0"
      :end="80"
      :status="PROGRESS_STATUS.PENDING"
      :rate="50"
    >
      <div class="text">
        Your payment is being securely processed.<br>
        Please stay with us as we complete this step.
      </div>
    </module-loading-progress>
  </div>
  <div class="modal-loading-progress-desktop" v-if="!isMobile && isShowModalLoadingProgress">
    <module-loading-progress
      :start="0"
      :end="80"
      :status="PROGRESS_STATUS.PENDING"
      :rate="50"
    >
      <div class="text">
        Your payment is being securely processed.<br>
        Please stay with us as we complete this step.
      </div>
    </module-loading-progress>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.modal-loading-progress-mobile {
  .text {
    text-align: center;
    font-family: "TWK Lausanne";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 16.8px */
  }
}

.modal-loading-progress-desktop {
  .text {
    text-align: center;
    font-family: "TWK Lausanne";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 16.8px */
  }
}
</style>
