<script setup>
import {useBaseStore} from "~/stores/base";
import {menuCategoryList} from "~/api/api.menu";
import ModuleCategoryChildren from "~/components/DrawerMenu/ModuleCategoryChildren.vue";

const emits = defineEmits(["close"])

const categories = ref([])
const isShowCategoryChildren = ref(false)
const categoryChildrenTitle = ref("")
const categoryChildrenList = ref([])

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)

const getData = async () => {
  try {
    const {data, pending, error, refresh} = await useAsyncData(() => menuCategoryList())
    console.log("category", data.value)
    categories.value = data.value.result
  } catch (e) {
    console.error(e)
  }
}

const handleCellClick = (cell) => {
  console.log(cell)
  isShowCategoryChildren.value = true
  categoryChildrenTitle.value = cell.name
  categoryChildrenList.value = cell.children
}

getData()
</script>

<template>
  <div class="module-categories-mobile" v-if="isMobile">
    <div class="menu-header">
      <div class="back" @click="emits('close')">
        <img src="@/assets/img/icon-arrow-left.svg" alt="back">
      </div>
      <div class="title">categories</div>
    </div>

    <div class="menu-body">
      <div class="block" v-for="category in categories">
        <div class="title">{{ category.name }}</div>
        <template v-for="cell in category.children">
          <!-- 有三级 -->
          <div class="menu-cell" @click="handleCellClick(cell)" v-if="cell.children">
            <div class="cell-left">
              <nuxt-link :to="cell.link" @click.stop>
                {{ cell.name }}
              </nuxt-link>
            </div>
            <div class="cell-right">
              <i class="iconfont icon-arrow-right"></i>
            </div>
          </div>
          <!-- 没三级 -->
          <nuxt-link class="menu-cell" :to="cell.link" v-else>
            <div class="cell-left">
              {{ cell.name }}
            </div>
          </nuxt-link>
        </template>
      </div>
    </div>

    <module-category-children
      v-if="isShowCategoryChildren"
      :title="categoryChildrenTitle"
      :list="categoryChildrenList"
      @close="isShowCategoryChildren = false"
    />
  </div>
  <div class="module-categories-desktop" v-else>
    <div class="menu-header">
      <div class="back" @click="emits('close')">
        <img src="@/assets/img/icon-arrow-left.svg" alt="back">
      </div>
      <div class="title">categories</div>
    </div>

    <div class="menu-body">
      <div class="block" v-for="category in categories">
        <div class="title">{{ category.name }}</div>
        <template v-for="cell in category.children">
          <!-- 有三级 -->
          <div class="menu-cell" @click="handleCellClick(cell)" v-if="cell.children">
            <div class="cell-left">
              <nuxt-link :to="cell.link" @click.stop>
                {{ cell.name }}
              </nuxt-link>
            </div>
            <div class="cell-right">
              <i class="iconfont icon-arrow-right"></i>
            </div>
          </div>
          <!-- 没三级 -->
          <nuxt-link class="menu-cell" :to="cell.link" v-else>
            <div class="cell-left">
              {{ cell.name }}
            </div>
          </nuxt-link>
        </template>
      </div>
    </div>

    <module-category-children
      v-if="isShowCategoryChildren"
      :title="categoryChildrenTitle"
      :list="categoryChildrenList"
      @close="isShowCategoryChildren = false"
    />
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-categories-mobile {
  width: 375px;
  height: calc(100vh - $mobile-header-height);
  margin-top: $mobile-header-height;
  padding-bottom: $mobile-safari-address-bar-height;
  overflow-y: auto;

  position: fixed;
  left: 0;
  top: 0;

  background-color: white;
}

.module-categories-desktop {
  width: 375px;
  height: calc(100vh - $desktop-header-height);
  margin-top: $desktop-header-height;
  overflow-y: scroll;

  position: fixed;
  left: 0;
  top: 0;

  background-color: white;
}
</style>
