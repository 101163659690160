<script setup>
import {useBaseStore} from "~/stores/base";
import {
  BNTO_STATUS,
  PATH_UNLOCK,
  SKU_STATUS,
} from "~/utils/constants";
import {Loading} from "@element-plus/icons-vue";

/** data **/
const isLoading = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const topInfo = computed(() => useCartStore().getterTopInfo)
const cartItemList = computed(() => useCartStore().getterCartItemList)
const appInit = computed(() => useBaseStore().getterAppInit)

const handlePlaceOrder = async () => {
  if (cartItemList.value.find(item => item.skuStatus === SKU_STATUS.OUT_OF_STOCK)) return
  useBaseStore().updateIsShowDrawerCartOrderPreview(true)
}
</script>

<template>
  <div class="module-cart-bottom-mobile" v-if="isMobile">
    <!-- 下单 -->
    <div
      v-if="topInfo.status === BNTO_STATUS.OPEN || topInfo.status === BNTO_STATUS.FULL"
      class="btn-primary"
      :class="{
        'btn-disabled': isLoading || cartItemList.find(item => item.skuStatus === SKU_STATUS.OUT_OF_STOCK),
      }"
      @click="handlePlaceOrder"
    >
      <el-icon v-if="isLoading" class="is-loading" color="white" size="16" style="margin-right: 8px">
        <Loading/>
      </el-icon>
      place order
    </div>
    <!-- 追踪运单 -->
    <nuxt-link
      :to="topInfo.deliveryTrackingUrl"
      v-else-if="topInfo.status === BNTO_STATUS.INCOMING || topInfo.status === BNTO_STATUS.PARTIALLY_INCOMING"
      class="btn-primary"
    >
      track bnto
    </nuxt-link>
    <div
      class="container"
      v-else-if="topInfo.status === BNTO_STATUS.DELIVERED || topInfo.status === BNTO_STATUS.PARTIALLY_DELIVERED"
    >
      <!-- faq -->
      <nuxt-link
        :to="appInit.agreementLink.faqSupportLink"
        class="btn-secondary"
      >
        <span>return bnto</span>
        <img src="@/assets/img/icon-info.svg" alt="icon">
      </nuxt-link>
      <!-- faq -->
      <nuxt-link
        :to="appInit.agreementLink.faqSupportLink"
        class="btn-secondary"
      >
        <span>unlock early</span>
        <img src="@/assets/img/icon-question-mark.svg" alt="icon">
      </nuxt-link>
    </div>
    <!-- 提前解锁 -->
    <nuxt-link
      :to="PATH_UNLOCK"
      class="btn-primary"
      v-else-if="topInfo.status === BNTO_STATUS.RETURN_IN_TRANSIT || topInfo.status === BNTO_STATUS.RETURNED"
    >
      unlock early
    </nuxt-link>
    <!-- 联系我们 -->
    <nuxt-link
      :to="appInit.agreementLink.contactUsLink"
      class="btn-secondary"
      v-else-if="topInfo.status === BNTO_STATUS.UNRESOLVED"
    >
      contact us
    </nuxt-link>
  </div>
  <div class="module-cart-bottom-desktop" v-else>
    <!-- 下单 -->
    <div
      v-if="topInfo.status === BNTO_STATUS.OPEN || topInfo.status === BNTO_STATUS.FULL"
      class="btn-primary"
      :class="{
        'btn-disabled': isLoading || cartItemList.find(item => item.skuStatus === SKU_STATUS.OUT_OF_STOCK),
      }"
      @click="handlePlaceOrder"
    >
      <el-icon v-if="isLoading" class="is-loading" color="white" size="16" style="margin-right: 8px">
        <Loading/>
      </el-icon>
      place order
    </div>
    <!-- 追踪运单 -->
    <nuxt-link
      :to="topInfo.deliveryTrackingUrl"
      v-else-if="topInfo.status === BNTO_STATUS.INCOMING || topInfo.status === BNTO_STATUS.PARTIALLY_INCOMING"
      class="btn-primary"
    >
      track bnto
    </nuxt-link>
    <div
      class="container"
      v-else-if="topInfo.status === BNTO_STATUS.DELIVERED || topInfo.status === BNTO_STATUS.PARTIALLY_DELIVERED"
    >
      <!-- faq -->
      <nuxt-link
        :to="appInit.agreementLink.faqSupportLink"
        class="btn-secondary"
      >
        <span>return bnto</span>
        <img src="@/assets/img/icon-info.svg" alt="icon">
      </nuxt-link>
      <!-- faq -->
      <nuxt-link
        :to="appInit.agreementLink.faqSupportLink"
        class="btn-secondary"
      >
        <span>unlock early</span>
        <img src="@/assets/img/icon-question-mark.svg" alt="icon">
      </nuxt-link>
    </div>
    <!-- 提前解锁 -->
    <nuxt-link
      :to="PATH_UNLOCK"
      class="btn-primary"
      v-else-if="topInfo.status === BNTO_STATUS.RETURN_IN_TRANSIT || topInfo.status === BNTO_STATUS.RETURNED"
    >
      unlock early
    </nuxt-link>
    <!-- 联系我们 -->
    <nuxt-link
      :to="appInit.agreementLink.contactUsLink"
      class="btn-secondary"
      v-else-if="topInfo.status === BNTO_STATUS.UNRESOLVED"
    >
      contact us
    </nuxt-link>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-cart-bottom-mobile {
  position: fixed;
  bottom: 0;
  z-index: 1;

  width: 375px;
  padding: 10px 16px 35px 16px;

  border-top: 1px solid $color-gray-300;
  background: white;
  box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.10);

  .container {
    display: flex;
    gap: 8px;

    a {
      width: 100%;
    }
  }
}

.module-cart-bottom-desktop {
  position: fixed;
  bottom: 0;
  z-index: 1;

  width: 375px;
  padding: 10px 16px 35px 16px;

  border-top: 1px solid $color-gray-300;
  background: white;
  box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.10);

  .container {
    display: flex;
    gap: 8px;

    a {
      width: 100%;
    }
  }
}
</style>
