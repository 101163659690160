import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function menuIndex() {
  return omniFetch({
    url: config.v1Path + config.menuIndexPath ,
    method: "get",
    params: {}
  })
}

export function menuCategoryList() {
  return omniFetch({
    url: config.v1Path + config.menuCategoryListPath ,
    method: "get",
    params: {}
  })
}
