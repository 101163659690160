<script setup>
import {useBaseStore} from "~/stores/base";

/** emits **/
const emits = defineEmits([])

/** props **/
const props = defineProps({
  productList: Array
})

/** data **/

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)

/** watch **/

/** methods **/

/** lifecycle **/

</script>

<template>
  <div class="module-big-card-mobile" v-if="isMobile">
    <nuxt-link :to="product.actionUrl" class="card" v-for="product in productList">
      <div class="product-info">
        <img class="product-img" :src="product.imgUrl" alt="img">
        <div class="info">
          <div class="brand">{{ product.brand }}</div>
          <div class="name">{{ product.productName }}</div>
          <div class="price">Retail: ${{ product.retailPrice }}</div>
        </div>
        <img class="arrow" src="@/assets/img/icon-arrow-right-gray.svg" alt="arrow">
      </div>
      <div class="desc" v-if="product.desc">
        {{ product.desc }}
      </div>
    </nuxt-link>
  </div>
  <div class="module-big-card-desktop" v-else>
    <nuxt-link :to="product.actionUrl" class="card" v-for="product in productList">
      <div class="product-info">
        <img :src="product.imgUrl" alt="img">
        <div class="info">
          <div class="brand">{{ product.brand }}</div>
          <div class="name">{{ product.productName }}</div>
          <div class="price">Retail: ${{ product.retailPrice }}</div>
        </div>
      </div>
      <div class="desc">
        <div class="text">{{ product.desc }}</div>
        <img src="@/assets/img/icon-arrow-right-gray.svg" alt="arrow">
      </div>
    </nuxt-link>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-big-card-mobile {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .card {
    border: 1px solid $color-gray-200;
    background-color: white;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .product-info {
      padding: 5px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      position: relative;

      .product-img {
        width: 57.143px;
        height: 80px;
        flex-shrink: 0;
        object-fit: cover;
        object-position: center center;
      }

      .info {
        padding: 8px;

        .brand {
          overflow: hidden;
          text-overflow: ellipsis;
          /* Text xs/Bold */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 650;
          line-height: 18px; /* 150% */
        }

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */
        }

        .price {
          overflow: hidden;
          text-overflow: ellipsis;
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */
        }
      }

      .arrow {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 0;
      }
    }

    .desc {
      width: 100%;
      height: 100%;
      border-top: 1px solid $color-gray-200;
      display: flex;
      align-items: center;

      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */
      padding: 10px;

      .text {
        width: 100%;
      }
    }
  }
}

.module-big-card-desktop {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .card {
    border: 1px solid $color-gray-200;
    height: 121px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .product-info {
      width: 195px;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      img {
        width: 78.716px;
        height: 110px;
        flex-shrink: 0;
        object-fit: cover;
        object-position: center center;
      }

      .info {
        padding: 8px;

        .brand {
          overflow: hidden;
          text-overflow: ellipsis;
          /* Text xs/Bold */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 650;
          line-height: 18px; /* 150% */
        }

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */
        }

        .price {
          overflow: hidden;
          text-overflow: ellipsis;
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */
        }
      }
    }

    .desc {
      width: 100%;
      height: 100%;
      border-left: 1px solid $color-gray-200;
      display: flex;
      align-items: center;
      gap: 10px;

      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */
      padding: 10px;

      .text {
        width: 100%;
      }
    }
  }
}
</style>
