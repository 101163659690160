<script setup>
import {useBaseStore} from "~/stores/base";
import PopoverLoginContent from "~/components/PopoverLoginContent.vue";

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
</script>

<template>
  <div class="module-guest-mobile" v-if="isMobile">
    <popover-login-content/>
  </div>
  <div class="module-guest-desktop" v-else>
    <popover-login-content/>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-guest-mobile {
}

.module-guest-desktop {
}
</style>
