<script setup>
import Modal from "./Modal.vue"
import Drawer from "./Drawer.vue"
import {useBaseStore} from "~/stores/base";

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isShowModalSizeCharts = computed(() => useBaseStore().getterIsShowModalSizeCharts)

/** methods **/
const handleClose = () => {
  useBaseStore().updateIsShowModalSizeCharts(false)
}
</script>

<template>
  <div class="modal-size-charts-mobile" v-if="isMobile">
    <Drawer :open="isShowModalSizeCharts" :position="'bottom'" :border-radius="'25px 25px 0 0'" :z-index="4" @close="handleClose">
      <div class="modal-size-charts">
        <div class="close">
          <img src="@/assets/img/icon-close-black.svg" alt="close" @click.stop="handleClose">
        </div>
        <div class="title">Size Conversion Chart</div>
        <div class="img">
          <img src="../assets/img/img-size-charts-mobile.png" alt="charts">
        </div>
      </div>
    </Drawer>
  </div>
  <div class="modal-size-charts-desktop" v-else>
    <Modal :open="isShowModalSizeCharts" @close="handleClose">
      <div class="modal-size-charts">
        <img class="close" src="@/assets/img/icon-close-black.svg" alt="close" @click.stop="handleClose">
        <div class="title">HOW TO MEASURE & SIZE GUIDE</div>
        <div class="middle">
          <img src="../assets/img/img-size-charts-middle.png" alt="middle">
        </div>
        <div class="form">
          <img src="../assets/img/img-size-charts-form.png" alt="form">
        </div>
      </div>
    </Modal>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.modal-size-charts-mobile {
  .modal-size-charts {
    width: 100vw;
    height: 158vw;
    position: relative;

    .close {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;

      border: 1px solid $color-BNTO-beige-dark;

      display: flex;
      width: 40px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 4px;

      img {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
      }
    }

    .title {
      /* Eyebrow/medium */
      font-family: "TWK Lausanne";
      font-size: 14px;
      font-style: normal;
      font-weight: 650;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.056px;
      text-transform: uppercase;

      padding: 16px;
      text-align: center;
    }

    .img {
      width: 100%;

      img {
        width: 100%;

      }
    }
  }
}

.modal-size-charts-desktop {
  .modal-size-charts {
    width: 957px;
    height: 596px;
    position: relative;
    padding: 20px;


    .title {
      text-align: center;
      leading-trim: both;

      text-edge: cap;
      font-family: "Druk Wide Cy";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }

    .middle {
      display: flex;
      margin-top: -5px;

      img {
        width: 100%;
      }
    }

    .form {
      display: flex;
      margin-top: -20px;

      img {
        width: 100%;
      }
    }

    .close {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }
  }
}
</style>
